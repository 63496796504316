<template>
  <base-section id="products">
    <div class="text-center" v-if="$route.name === 'produk'">
    <v-chip
      v-for="data in filter"
      :key="data.flag"
      :class="data.flag === select ?'ma-2 primary':'ma-2 '"
      @click="select = data.flag"
      label
    >
      <v-icon left color="white">{{ data.icon }}</v-icon>
      {{ data.id }}
    </v-chip>
    </div>
    <div 
    data-aos="fade-up"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    data-aos-anchor-placement="top-center"
    v-if="$route.name === 'beranda' || $route.name ===''">
    <base-section-heading title="Selamat Datang" class="text-uppercase">
      <div class="text-capitalize justify-center">PT Orion Penjaminan Indonesia merupakan Perusahaan Penjaminan yang didirikan pada tanggal 12 April 2023 yang bertujuan untuk melayani penjaminan atas kewajiban finansial para pelaku usaha atau nasabah baik jasa keuangan perbankan maupun non perbankan di Indonesia, melalui pemanfaatan Teknologi IT yang modern sejak proses penjaminan sampai dengan pelayanan klaim.</div>
    </base-section-heading>
    </div>
    <div 
    data-aos="fade-right"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    data-aos-anchor-placement="top-center">
    <v-container>
      <template>
        <v-row>
        <v-col
          v-for="(data, i) in filtered"
          :key="i"
          cols="12"
          xs="12"
          md="4"
          sm="6"
          class="pa-1"
          @click="Detail(data.web_product_id)"
        >
        <v-card
          class="mx-auto cursor"
          max-width="400"
          height="200px"
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="require('@/assets/'+data.web_product_src_url)"
            :alt="data.web_product_name"
          >
            <!-- <v-card-title style="margin-right:100px" v-html="`${ReplaceSpace(data.web_product_name)}`"></v-card-title> -->
          </v-img>
          <v-card-text>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col class="text-center">
        <base-btn
          :block="$vuetify.breakpoint.smAndDown"
          color="accent"
          large
          outlined
          @click = "GoTo"
          v-if="$route.name === 'beranda' || $route.name === 'produk-detail' || $route.name ===''"
          class = "text-capitalize"
        >
          Lihat Produk Lainnya
        </base-btn>
      </v-col>
      </v-row>
      </template>
    </v-container>
    <v-snackbar
        v-model="snackbar.value"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn
          color="fourth"
          text
          v-bind="attrs"
          @click="snackbar.value = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProducts',
    data: () => ({
      select: 'semua',
      filter: [
      { id: 'Semua', flag: 'semua', icon: 'mdi-check' },
      { id: 'Cash Loan', flag: '01', icon: 'mdi-label' },
      { id: 'Non Cash Loan', flag: '02', icon: 'mdi-fire' },
      ],
      posts: [],
      snackbar: {
        value: false,
        text: ''
      }
    }),
    computed: {
      filtered () {
        const self = this;
        if (self.select === 'semua') {
          return self.posts;
        } else {
          return self.posts.filter(function(data) {
            return self.select === data.web_product_cob;
          });
        }
      }
    },
    created () {
        this.List()
    },
    methods: {
      List () {
        this.posts = [
                      {'web_product_id':'0101','web_product_name':'Penjaminan Kredit Multiguna','web_product_src_url':'product-penjaminan-kredit-multiguna.jpeg','web_product_cob':'01'},
                      // {'web_product_id':'0102','web_product_name':'Penjaminan Kredit Pemilikan Rumah','web_product_src_url':'product-kredit-pemilikan-rumah.jpeg','web_product_cob':'01'},
                      {'web_product_id':'0103','web_product_name':'Penjaminan Kredit Mikro','web_product_src_url':'product-penjaminan-kredit-mikro.jpeg','web_product_cob':'01'},
                      {'web_product_id':'0104','web_product_name':'Penjaminan Kredit Umum','web_product_src_url':'product-penjaminan-kredit-umum.jpeg','web_product_cob':'01'},
                      // {'web_product_id':'0105','web_product_name':'Penjaminan Konstruksi/Pengadaan Barang & Jasa','web_product_src_url':'product-penjaminan-konstruksi-barang-jasa.jpeg','web_product_cob':'01'},
                      // {'web_product_id':'0106','web_product_name':'Penjaminan Supply Chain Financing','web_product_src_url':'product-supply-chain-financing.jpeg','web_product_cob':'01'},
                      // {'web_product_id':'0201','web_product_name':'Surety Bond','web_product_src_url':'product-surety-bond.jpeg','web_product_cob':'02'},
                      // {'web_product_id':'0202','web_product_name':'Kontra Bank Garansi','web_product_src_url':'product-kontra-bank-garansi.jpeg','web_product_cob':'02'},
                      // {'web_product_id':'0203','web_product_name':'Customs Bond','web_product_src_url':'product-customs-bond.jpeg','web_product_cob':'02'},
                      ]
        // let limits = '6'
        // if (this.$route.name === 'produk') {
        //   limits = ''
        // }
        // this.loading  = true
        // let formdata = {
        //       web_product_actived: 'Y',
        //       limit: limits,
        //       order_by: 'web_product_sort',
        //       order_type: 'ASC',
        // }
        // let param      = this.$functions.ParamPOST(formdata)
        // this.$axios.post(this.$functions.UrlPOST('apiWebProductList'),param,{
        // headers: {
        //     'Content-Type': 'text/plain; charset=ISO-8859-1'
        // }
        // })
        // .then(response => {
        //     let data = response.data
        //     if (data.length > 0) {
        //       if (data[0].feedback === 'Y') {
        //         this.posts = data
        //       } else {
        //         this.snackbar.value = true
        //         this.snackbar.text  = data[0].feedback
        //       }
        //     } else {
        //       this.posts = []
        //     }
        //     this.loading  = false
        // })
        // .catch(e => {
        //     this.snackbar.value = true
        //     this.snackbar.text  = e
        //     this.loading  = false
        // })
      },
      Detail (url) {
        this.$router.push({name: 'produk-detail', params: { url: url }})
      },
      ReplaceSpace (data) {
        return data.replace('nan ', 'nan<br>')
      },
      GoTo () {
        this.$router.push({name: 'produk'})
      }
    }
  }
</script>
